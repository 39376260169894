
import * as vercelRuntime$QRKa9qC17w from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.0_@upstash+redis@1.34.0_ioredis@5.4.1_magicast@0.3.5_rollup@3.29.4_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "caseStudy": {
      "modifiers": {
        "width": 960,
        "format": "webp"
      }
    },
    "logo": {
      "modifiers": {
        "width": 256,
        "height": 256,
        "format": "webp"
      }
    },
    "cover": {
      "modifiers": {
        "width": 1920,
        "height": 384,
        "format": "webp"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "trustfolio.co",
    "mrcrstatic.blob.core.windows.net",
    "tf-client-public.ams3.cdn.digitaloceanspaces.com",
    "tf-client-public.ams3.digitaloceanspaces.com",
    "cdn.trustfolio.work",
    "local.storage.local.nhost.run:444"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$QRKa9qC17w, defaults: {} }
}
        